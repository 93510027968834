import { Address, FuelType, ModelCategory, VehicleDimensions, VehicleType } from '../sharedComponents/common.types';

export enum CompoundManagementView {
    INVENTORY = 'outboundOrderBook.compoundManagement.vehicleInventory.table.toolbar.inventory',
    ARCHIVE = 'outboundOrderBook.compoundManagement.vehicleInventory.table.toolbar.archive',
}

export type CompoundLocation = {
    readonly identifier: string;
    readonly city: string;
    readonly timeZone: string;
};

export type FreightForwarder = {
    readonly name: string;
    readonly additionalName: string | undefined;
};

export type Arrival = {
    readonly arrivalCompletedAt: Date;
    readonly freightForwarder: FreightForwarder | undefined;
};

export type Departure = {
    readonly departureCompletedAt: Date;
    readonly freightForwarder: FreightForwarder | undefined;
};

export type Model = {
    readonly name: string;
    readonly version: string | undefined;
    readonly group: string;
    readonly manufacturer: string | undefined;
    readonly fuelType: FuelType | undefined;
    readonly category: ModelCategory | undefined;
};

export type Vehicle = {
    readonly vin: string;
    readonly model: Model;
    readonly vehicleType: VehicleType | undefined;
    readonly manufacturedAt: Date | undefined;
    readonly finalDestination: FinalDestination | undefined;
    readonly dimensions: VehicleDimensions | undefined;
    readonly weight: number | undefined;
    readonly colour: string | undefined;
    readonly licensePlate: string | undefined;
};

export type FinalDestination = {
    identifier: string | undefined;
    address: Partial<Address> | undefined;
};

export type ArchivedVehicle = {
    readonly id: string;
    readonly compoundLocation: CompoundLocation;
    readonly arrival: Arrival;
    readonly departure: Departure;
    readonly vehicle: Vehicle;
};

export type InventoryVehicle = {
    readonly id: string;
    readonly compoundLocation: CompoundLocation;
    readonly arrival: Arrival;
    readonly vehicle: Vehicle;
};

export type CompoundVehicle = ArchivedVehicle | InventoryVehicle;

export type InventoryVehiclesPage = {
    readonly items: InventoryVehicle[];
    readonly hasMore: boolean;
};

export type ArchivedVehiclesPage = {
    readonly items: ArchivedVehicle[];
    readonly hasMore: boolean;
};
