import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import { FormattedMessage } from 'react-intl';
import { CompoundManagementView } from '../compoundManagement.types';

type CompoundManagementViewSelectionProps = {
    activeView: CompoundManagementView;
    setActiveView: (newView: CompoundManagementView) => void;
};

export const CompoundManagementViewSelection = ({
    activeView,
    setActiveView,
}: CompoundManagementViewSelectionProps) => (
    <div className="btn-group" data-testid="toolbar-button-group">
        <Button
            active={activeView === CompoundManagementView.INVENTORY}
            onClick={() => setActiveView(CompoundManagementView.INVENTORY)}
        >
            <span className="rioglyph rioglyph-car" />
            <FormattedMessage id={CompoundManagementView.INVENTORY} />
        </Button>
        <Button
            active={activeView === CompoundManagementView.ARCHIVE}
            onClick={() => setActiveView(CompoundManagementView.ARCHIVE)}
        >
            <span className="rioglyph rioglyph-folder-closed" />
            <FormattedMessage id={CompoundManagementView.ARCHIVE} />
        </Button>
    </div>
);
