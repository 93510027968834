import { ColumnDefinition } from '../../../sharedComponents/SortedTableHeader';

export enum VehicleArchiveTableColumn {
    VIN = 'vin',
    LOCATION = 'location',
    MANUFACTURER = 'manufacturer',
    MODEL = 'model',
    DISPATCH_TYPE = 'dispatchType',
    FUEL_TYPE = 'fuelType',
    MODEL_VERSION = 'modelVersion',
    ARRIVAL_DATE = 'arrivalDate',
    ARRIVAL_FREIGHT_FORWARDER = 'arrivalFreightForwarder',
    DEPARTURE_DATE = 'departureDate',
    DEPARTURE_FREIGHT_FORWARDER = 'departureFreightForwarder',
}

export const vehicleArchiveColumnDefinition: ColumnDefinition<VehicleArchiveTableColumn>[] = [
    {
        column: VehicleArchiveTableColumn.VIN,
        labelId: 'outboundOrderBook.common.heading.vin',
        sortable: false,
    },
    {
        column: VehicleArchiveTableColumn.LOCATION,
        labelId: 'outboundOrderBook.compoundManagement.table.heading.location',
        sortable: false,
    },
    {
        column: VehicleArchiveTableColumn.MANUFACTURER,
        labelId: 'outboundOrderBook.common.heading.manufacturer',
        sortable: false,
    },
    {
        column: VehicleArchiveTableColumn.MODEL,
        labelId: 'outboundOrderBook.common.heading.model',
        sortable: false,
    },
    {
        column: VehicleArchiveTableColumn.DISPATCH_TYPE,
        labelId: 'outboundOrderBook.common.heading.vehicleType',
        sortable: false,
    },
    {
        column: VehicleArchiveTableColumn.FUEL_TYPE,
        labelId: 'outboundOrderBook.common.heading.fuelType',
        sortable: false,
    },
    {
        column: VehicleArchiveTableColumn.MODEL_VERSION,
        labelId: 'outboundOrderBook.compoundManagement.table.heading.modelVersion',
        sortable: false,
    },
    {
        column: VehicleArchiveTableColumn.ARRIVAL_DATE,
        labelId: 'outboundOrderBook.compoundManagement.table.heading.arrivalDate',
        sortable: false,
    },
    {
        column: VehicleArchiveTableColumn.ARRIVAL_FREIGHT_FORWARDER,
        labelId: 'outboundOrderBook.compoundManagement.table.heading.arrivalCarrier',
        sortable: false,
    },
    {
        column: VehicleArchiveTableColumn.DEPARTURE_DATE,
        labelId: 'outboundOrderBook.compoundManagement.table.heading.departureDate',
        sortable: false,
    },
    {
        column: VehicleArchiveTableColumn.DEPARTURE_FREIGHT_FORWARDER,
        labelId: 'outboundOrderBook.compoundManagement.table.heading.departureCarrier',
        sortable: false,
    },
];
