import { ApiFinalDestination, ApiOptionalAddress } from '../../types/compoundManagement/finalDestination.types';
import { Address } from '../../../sharedComponents/common.types';
import { FinalDestination } from '../../../compoundManagement/compoundManagement.types';

export const mapToPartialAddress = (apiAddress: ApiOptionalAddress): Partial<Address> | undefined => {
    const { additional_name_information, additional_street_information, ...rest } = apiAddress;
    const addressIsEmpty = Object.values(rest).every((value) => value === null || value === undefined);
    return addressIsEmpty
        ? undefined
        : {
              name:
                  apiAddress.name &&
                  `${apiAddress.name}${
                      apiAddress.additional_name_information != null ? ' ' + apiAddress.additional_name_information : ''
                  }`,
              street:
                  apiAddress.street &&
                  `${apiAddress.street}${
                      apiAddress.additional_street_information != null
                          ? ' ' + apiAddress.additional_street_information
                          : ''
                  }`,
              postalCode: apiAddress.postal_code,
              city: apiAddress.city,
              countryCode: apiAddress.country_code,
          };
};

export const mapToFinalDestination = (apiFinalDestination: ApiFinalDestination): FinalDestination => ({
    identifier: apiFinalDestination.identifier,
    address: apiFinalDestination.address && mapToPartialAddress(apiFinalDestination.address),
});
