import { FormattedMessage } from 'react-intl';
import { DateTime } from '../../../sharedComponents/BaseTableComponents';
import { NoValue } from '../../../sharedComponents/NoValue';
import { ArchivedVehicle } from '../../compoundManagement.types';
import { vehicleArchiveColumnDefinition, VehicleArchiveTableColumn } from './VehicleArchiveTableColumn';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/hooks';
import { compoundManagementSlice, getSelectedCompoundVehicle } from '../../../reducers/compoundManagementSlice';

type TableRowDataProps = {
    column: VehicleArchiveTableColumn;
    vehicle: ArchivedVehicle;
};

export const TableDataContent = ({ vehicle, column }: TableRowDataProps) => {
    const spanOrNoValue = (text: string | undefined) => (Boolean(text) ? <span>{text}</span> : <NoValue />);

    switch (column) {
        case VehicleArchiveTableColumn.VIN:
            return <div className="ellipsis-1">{vehicle.vehicle.vin}</div>;
        case VehicleArchiveTableColumn.LOCATION:
            return <span>{vehicle.compoundLocation.city}</span>;
        case VehicleArchiveTableColumn.MANUFACTURER:
            return spanOrNoValue(vehicle.vehicle.model.manufacturer);
        case VehicleArchiveTableColumn.MODEL:
            return <span>{vehicle.vehicle.model.name}</span>;
        case VehicleArchiveTableColumn.DISPATCH_TYPE:
            return vehicle.vehicle.vehicleType === undefined ? (
                <NoValue />
            ) : (
                <div className="ellipsis-1">
                    <FormattedMessage id={`outboundOrderBook.common.vehicleType.${vehicle.vehicle.vehicleType}`} />
                </div>
            );
        case VehicleArchiveTableColumn.FUEL_TYPE:
            const fuelType = vehicle.vehicle.model.fuelType;
            if (fuelType === undefined) {
                return <NoValue />;
            } else {
                const messageId = `outboundOrderBook.common.fuelType.${fuelType}`;
                return (
                    <div className="ellipsis-1">
                        <FormattedMessage id={messageId} />
                    </div>
                );
            }
        case VehicleArchiveTableColumn.MODEL_VERSION:
            return spanOrNoValue(vehicle.vehicle.model.version);
        case VehicleArchiveTableColumn.ARRIVAL_DATE:
            return (
                <DateTime dateTime={vehicle.arrival.arrivalCompletedAt} timeZone={vehicle.compoundLocation.timeZone} />
            );
        case VehicleArchiveTableColumn.ARRIVAL_FREIGHT_FORWARDER:
            return vehicle.arrival.freightForwarder === undefined ? (
                <NoValue />
            ) : (
                <div className="ellipsis-1">
                    {vehicle.arrival.freightForwarder.name} {vehicle.arrival.freightForwarder.additionalName}
                </div>
            );
        case VehicleArchiveTableColumn.DEPARTURE_DATE:
            return (
                <DateTime
                    dateTime={vehicle.departure.departureCompletedAt}
                    timeZone={vehicle.compoundLocation.timeZone}
                />
            );
        case VehicleArchiveTableColumn.DEPARTURE_FREIGHT_FORWARDER:
            return vehicle.departure.freightForwarder === undefined ? (
                <NoValue />
            ) : (
                <div className="ellipsis-1">
                    {vehicle.departure.freightForwarder.name} {vehicle.departure.freightForwarder.additionalName}
                </div>
            );
        default:
            throw new Error('exhaustive switch exception - table content');
    }
};

const TableRowData = ({ column, vehicle }: TableRowDataProps) => (
    <td key={column}>
        <TableDataContent column={column} vehicle={vehicle} />
    </td>
);

type VehicleArchiveTableRowProps = {
    readonly archivedVehicle: ArchivedVehicle;
};

export const VehicleArchiveTableRow = ({ archivedVehicle }: VehicleArchiveTableRowProps) => {
    const dispatch = useAppDispatch();
    const selectedCompoundVehicle = useAppSelector(getSelectedCompoundVehicle);

    const handleRowClick = () => {
        const isRowSelected = archivedVehicle.id === selectedCompoundVehicle?.id;
        if (isRowSelected) {
            dispatch(compoundManagementSlice.actions.deselectCompoundVehicle());
        } else {
            dispatch(compoundManagementSlice.actions.selectCompoundVehicle(archivedVehicle));
        }
    };

    return (
        <tr key={archivedVehicle.id} onClick={handleRowClick}>
            {vehicleArchiveColumnDefinition.map((def) => (
                <TableRowData key={def.column} column={def.column} vehicle={archivedVehicle} />
            ))}
        </tr>
    );
};
