import { FormattedMessage } from 'react-intl';
import { Table } from '../../shared/table/Table';
import { InventoryVehicle } from '../../compoundManagement.types';
import { vehicleInventoryColumnDefinition } from './VehicleInventoryTableColumn';
import { VehicleInventoryTableRow } from './VehicleInventoryTableRow';

type VehicleInventoryTableProps = {
    readonly vehicles: readonly InventoryVehicle[];
};

export const VehicleInventoryTable = ({ vehicles }: VehicleInventoryTableProps) => {
    return (
        <Table
            columnDefinition={vehicleInventoryColumnDefinition}
            items={vehicles}
            notFoundMessage={<FormattedMessage id="outboundOrderBook.compoundManagement.vehicleInventory.notFound" />}
        >
            {vehicles.map((vehicle: InventoryVehicle) => (
                <VehicleInventoryTableRow key={vehicle.id} inventoryVehicle={vehicle} />
            ))}
        </Table>
    );
};
