import ErrorState from '@rio-cloud/rio-uikit/lib/es/ErrorState';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { isUserSessionExpired } from '../../../../configuration/login/loginSlice';
import { useAppSelector } from '../../../../configuration/setup/hooks';
import { useGetInventoryVehiclesQuery } from '../../api/compoundManagementApi';
import { handleQueryError } from '../../notifications/ErrorNotification';
import { SortDirection, TableSort } from '../../sharedComponents/SortedTableHeader';
import { useSetUserTriggeredFetching } from '../../sharedComponents/userTriggeredFetchingHook';
import { LoadMoreButton } from '../../sharedComponents/LoadMoreButton';
import { VehicleInventoryTableToolbar } from './VehicleInventoryTableToolbar';
import { VehicleInventoryTable } from './table/VehicleInventoryTable';
import { VehicleInventoryTableColumn } from './table/VehicleInventoryTableColumn';

export const DEFAULT_COMPOUND_INVENTORY_LIMIT_SIZE = 100;
const POLLING_INTERVAL = 60000;

export const VehicleInventory: FunctionComponent = () => {
    const isSessionExpired = useAppSelector(isUserSessionExpired);

    const [limit, setLimit] = useState(DEFAULT_COMPOUND_INVENTORY_LIMIT_SIZE);

    const defaultSort: TableSort<VehicleInventoryTableColumn> = {
        dir: SortDirection.DESCENDING,
        column: VehicleInventoryTableColumn.ARRIVAL_DATE,
    };

    const {
        data: inventoryPage,
        isLoading,
        isFetching,
        isError,
        error,
    } = useGetInventoryVehiclesQuery(
        {
            limit,
            sortBy: defaultSort,
        },
        {
            pollingInterval: isSessionExpired ? undefined : POLLING_INTERVAL,
        },
    );

    useSetUserTriggeredFetching({
        isFetching,
        depsWhichTriggerUserFetching: [],
    });

    const inventoryVehicles = inventoryPage?.items;

    if (isError) {
        console.error(error);
        handleQueryError(error);
    }

    if (isLoading) {
        return (
            <div data-testid="spinner">
                <Spinner />
            </div>
        );
    }

    if (inventoryVehicles === undefined) {
        return (
            <ErrorState
                headline={<FormattedMessage id="intl-msg:common-message.error.generic.headline" />}
                message={<FormattedMessage id="intl-msg:common-message.error.generic.message" />}
            />
        );
    }

    return (
        <>
            <VehicleInventoryTableToolbar />
            <VehicleInventoryTable vehicles={inventoryVehicles} />
            {inventoryVehicles.length > 0 && (
                <LoadMoreButton
                    onLoadMore={() => setLimit(limit + DEFAULT_COMPOUND_INVENTORY_LIMIT_SIZE)}
                    hasMoreToLoad={inventoryPage?.hasMore ?? false}
                />
            )}
        </>
    );
};
