import TableToolbar from '@rio-cloud/rio-uikit/lib/es/TableToolbar';
import TableViewToggles from '@rio-cloud/rio-uikit/lib/es/TableViewToggles';
import { FunctionComponent } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/hooks';
import { compoundManagementSlice } from '../../reducers/compoundManagementSlice';
import { getTableViewType, viewSlice } from '../../reducers/viewSlice';
import { CompoundManagementViewSelection } from '../toolbar/CompoundManagementViewSelection';
import { CompoundManagementView } from '../compoundManagement.types';

export const VehicleArchiveTableToolbar: FunctionComponent = () => {
    const viewType = useAppSelector(getTableViewType);
    const dispatch = useAppDispatch();

    const handleSwitchView = (newView: CompoundManagementView) =>
        dispatch(compoundManagementSlice.actions.switchView(newView));

    const handleViewTypeChange = (newViewType: TableViewToggles) =>
        dispatch(viewSlice.actions.setTableViewType(newViewType));

    return (
        <TableToolbar>
            <div className="table-toolbar-container">
                <div className="table-toolbar-group-left">
                    <div className="table-toolbar-column">
                        <div className="btn-toolbar table-btn-toolbar">
                            <CompoundManagementViewSelection
                                activeView={CompoundManagementView.ARCHIVE}
                                setActiveView={handleSwitchView}
                            />
                        </div>
                    </div>
                </div>
                <div className="table-toolbar-group-right">
                    <div className="table-toolbar-column">
                        <TableViewToggles initialViewType={viewType} onViewTypeChange={handleViewTypeChange} />
                    </div>
                </div>
            </div>
        </TableToolbar>
    );
};
