import {
    FuelType,
    Model,
    ModelCategory,
    Vehicle,
    VehicleConditionType,
    VehicleDimensions,
    VehicleId,
    VehicleType,
} from '../../sharedComponents/common.types';
import { parseOptionalStringDate } from '../../sharedComponents/dateHelper';
import {
    ApiCategory,
    ApiFuelType,
    ApiModel,
    ApiVehicle,
    ApiVehicleConditionType,
    ApiVehicleDimensions,
    ApiVehicleId,
    ApiVehicleType,
} from '../types/vehicleApi.types';
import { NonEmptyString } from 'io-ts-types';
import { mapToAddress } from './addressMappers';

export const mapToVehicle = (apiVehicle: ApiVehicle): Vehicle => {
    return {
        id: mapToVehicleId(apiVehicle.id),
        model: mapToModel(apiVehicle.model),
        weight: apiVehicle.weight,
        colour: apiVehicle.colour,
        dimensions: apiVehicle.dimensions && mapToVehicleDimensions(apiVehicle.dimensions),
        vehicleType: apiVehicle.vehicle_type !== undefined ? mapToVehicleType(apiVehicle.vehicle_type) : undefined,
        licensePlate: apiVehicle.license_plate,
        specialInstructions: apiVehicle.special_instructions ?? [],
        deliveryConditions:
            apiVehicle.delivery_condition !== undefined
                ? mapToVehicleConditionType(apiVehicle.delivery_condition)
                : undefined,
        hasPriority: apiVehicle.has_priority,
        manufacturedAt: parseOptionalStringDate(apiVehicle.manufactured_at),
        finalDestination:
            apiVehicle.final_destination?.address !== undefined
                ? mapToAddress(apiVehicle.final_destination?.address)
                : undefined,
    };
};

export const mapToVehicleDimensions = (apiDimension: ApiVehicleDimensions): VehicleDimensions => ({
    length: apiDimension.length,
    width: apiDimension.width,
    height: apiDimension.height,
});

export const mapToApiVehicleId = (vehicleId: VehicleId): ApiVehicleId => ({
    vin: vehicleId.vin as NonEmptyString,
    production_number: vehicleId.productionNumber as NonEmptyString,
});

export const mapToVehicleId = (apiVehicleId: ApiVehicleId): VehicleId => {
    return {
        vin: apiVehicleId.vin,
        productionNumber: apiVehicleId.production_number,
    };
};

const mapToModel = (apiModel: ApiModel): Model => {
    return {
        name: apiModel.name,
        modelGroup: apiModel.model_group,
        category: apiModel.category !== undefined ? mapToCategory(apiModel.category) : undefined,
        manufacturer: apiModel.manufacturer,
        fuelType: apiModel.fuel_type !== undefined ? mapToFuelType(apiModel.fuel_type) : undefined,
    };
};

export const mapToCategory = (apiCategory: ApiCategory): ModelCategory => {
    switch (apiCategory) {
        case ApiCategory.CAMPER_VAN:
            return ModelCategory.CAMPER_VAN;
        case ApiCategory.CAR:
            return ModelCategory.CAR;
        case ApiCategory.MOTORCYCLE:
            return ModelCategory.MOTORCYCLE;
        case ApiCategory.QUAD:
            return ModelCategory.QUAD;
        case ApiCategory.SPECIAL_VEHICLE:
            return ModelCategory.SPECIAL_VEHICLE;
        case ApiCategory.TRIKE:
            return ModelCategory.TRIKE;
        case ApiCategory.TRUCK:
            return ModelCategory.TRUCK;
        case ApiCategory.VAN:
            return ModelCategory.VAN;
    }
};

export const mapToFuelType = (apiFuelType: ApiFuelType): FuelType => {
    switch (apiFuelType) {
        case ApiFuelType.COMPRESSED_NATURAL_GAS:
            return FuelType.COMPRESSED_NATURAL_GAS;
        case ApiFuelType.DIESEL:
            return FuelType.DIESEL;
        case ApiFuelType.DIESEL_ELECTRIC:
            return FuelType.DIESEL_ELECTRIC;
        case ApiFuelType.ELECTRIC:
            return FuelType.ELECTRIC;
        case ApiFuelType.HYDROGEN:
            return FuelType.HYDROGEN;
        case ApiFuelType.LIQUID_NATURAL_GAS:
            return FuelType.LIQUID_NATURAL_GAS;
        case ApiFuelType.LIQUID_PROPANE_GAS:
            return FuelType.LIQUID_PROPANE_GAS;
        case ApiFuelType.PETROL:
            return FuelType.PETROL;
        case ApiFuelType.PETROL_ELECTRIC:
            return FuelType.PETROL_ELECTRIC;
    }
};

export const mapToVehicleType = (apiVehicleType: ApiVehicleType): VehicleType => {
    switch (apiVehicleType) {
        case ApiVehicleType.NEW:
            return VehicleType.NEW;
        case ApiVehicleType.USED:
            return VehicleType.USED;
        case ApiVehicleType.OTHER:
            return VehicleType.OTHER;
    }
};

export const mapToVehicleConditionType = (apiVehicleConditionType: ApiVehicleConditionType): VehicleConditionType => {
    switch (apiVehicleConditionType) {
        case ApiVehicleConditionType.CONTACT_UNKNOWN:
            return VehicleConditionType.CONTACT_UNKNOWN;
        case ApiVehicleConditionType.NO_KEYS:
            return VehicleConditionType.NO_KEYS;
        case ApiVehicleConditionType.NOT_READY_FOR_PICKUP:
            return VehicleConditionType.NOT_READY_FOR_PICKUP;
        case ApiVehicleConditionType.NOT_ON_SITE:
            return VehicleConditionType.NOT_ON_SITE;
        case ApiVehicleConditionType.NOT_READY_TO_MOVE:
            return VehicleConditionType.NOT_READY_TO_MOVE;
        case ApiVehicleConditionType.NOT_READY_TO_DRIVE:
            return VehicleConditionType.NOT_READY_TO_DRIVE;
        case ApiVehicleConditionType.OTHER:
            return VehicleConditionType.OTHER;
    }
};

export const mapToVehicleConditionApi = (type: VehicleConditionType): ApiVehicleConditionType => {
    switch (type) {
        case VehicleConditionType.CONTACT_UNKNOWN:
            return ApiVehicleConditionType.CONTACT_UNKNOWN;
        case VehicleConditionType.NO_KEYS:
            return ApiVehicleConditionType.NO_KEYS;
        case VehicleConditionType.NOT_READY_FOR_PICKUP:
            return ApiVehicleConditionType.NOT_READY_FOR_PICKUP;
        case VehicleConditionType.NOT_ON_SITE:
            return ApiVehicleConditionType.NOT_ON_SITE;
        case VehicleConditionType.NOT_READY_TO_MOVE:
            return ApiVehicleConditionType.NOT_READY_TO_MOVE;
        case VehicleConditionType.NOT_READY_TO_DRIVE:
            return ApiVehicleConditionType.NOT_READY_TO_DRIVE;
        case VehicleConditionType.OTHER:
            return ApiVehicleConditionType.OTHER;
    }
};
