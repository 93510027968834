import {
    Location,
    CompoundTransportOrder,
    CompoundTransportOrderPage,
    CompoundTransportOrderStatus,
} from '../../compoundTransportOrders/compoundTransportOrder.types';
import { TableIncomingCompoundTransportOrderColumn } from '../../compoundTransportOrders/table/IncomingCompoundTransportOrderColumns';
import { SortDirection, TableSort } from '../../sharedComponents/SortedTableHeader';
import { parseStringDate } from '../../sharedComponents/dateHelper';
import type { GetCompoundTransportOrderQueryParams } from '../compoundTransportOrderApi';
import { ApiStatus } from '../types/transportAssignment/transportAssignmentsApi.types';
import {
    ApiCarrier,
    ApiSort,
    ApiCompoundTransportOrder,
    ApiCompoundTransportOrderPage,
    ApiCompoundTransportOrderQueryParameter,
} from '../types/compoundTransportOrderApi.types';
import { mapFilterableStatusesToApiStatuses } from './outgoingRequestMappers';
import { mapToVehicle } from './vehicleMappers';
import { mapToTimeZone } from './timeZoneMappers';
import { ApiAddressAndContact } from '../types/transportAssignment/addressAndContactApi.types';
import { mapToAddress } from './addressMappers';

export const mapToCompoundTransportOrderQueryParameters = (
    queryParameters: GetCompoundTransportOrderQueryParams,
): ApiCompoundTransportOrderQueryParameter => {
    return {
        expected_delivery_after: queryParameters.expectedDeliveryAfter.toISOString(),
        expected_delivery_before: queryParameters.expectedDeliveryBefore.toISOString(),
        unloading_address_id: queryParameters.locationId,
        carrier_name: queryParameters.carrierName,
        limit: queryParameters?.limit?.toFixed(0),
        sort: queryParameters.sortBy && mapToApiSort(queryParameters.sortBy),
        statuses: queryParameters?.statuses && mapFilterableStatusesToApiStatuses(queryParameters.statuses),
        q: queryParameters?.searchTerm,
    };
};

export const mapToApiSort = (sorting: TableSort<TableIncomingCompoundTransportOrderColumn>): ApiSort => {
    if (sorting.dir === SortDirection.ASCENDING) {
        switch (sorting.column) {
            case TableIncomingCompoundTransportOrderColumn.EXPECTED_DELIVERY_AT:
                return ApiSort.EXPECTED_DELIVERY_AT_ASC;
            case TableIncomingCompoundTransportOrderColumn.CARRIER:
                return ApiSort.CARRIER_ASC;
        }
    } else if (sorting.dir === SortDirection.DESCENDING) {
        switch (sorting.column) {
            case TableIncomingCompoundTransportOrderColumn.EXPECTED_DELIVERY_AT:
                return ApiSort.EXPECTED_DELIVERY_AT_DESC;
            case TableIncomingCompoundTransportOrderColumn.CARRIER:
                return ApiSort.CARRIER_DESC;
        }
    }
    throw new Error('Specified sort order is not implemented');
};

export const mapToCompoundTransportOrderPage = (apiPage: ApiCompoundTransportOrderPage): CompoundTransportOrderPage => {
    return {
        items: apiPage.items.map(mapToCompoundTransportOrder),
        summary: {
            numberOfTransports: apiPage.summary.number_of_transports,
            numberOfVehicles: apiPage.summary.number_of_vehicles,
        },
        hasMore: apiPage.has_more,
    };
};

export const mapToCarrier = (carrier: ApiCarrier): string | undefined => {
    if (carrier.name != null) {
        return `${carrier.name}${carrier.additional_name != null ? carrier.additional_name : ''}`;
    } else if (carrier.additional_name != null) {
        return carrier.additional_name;
    } else {
        return undefined;
    }
};

const mapToLocation = (apiLocation: ApiAddressAndContact): Location => ({
    identifier: { id: apiLocation.identifier.id },
    address: apiLocation.address ? mapToAddress(apiLocation.address) : undefined,
});

const mapToCompoundTransportOrder = (apiCompoundTransportOrder: ApiCompoundTransportOrder): CompoundTransportOrder => {
    return {
        id: apiCompoundTransportOrder.id,
        vehicle: mapToVehicle(apiCompoundTransportOrder.vehicle),
        expectedDeliveryAt: parseStringDate(apiCompoundTransportOrder.expected_delivery_at),
        deliveryLocation: mapToLocation(apiCompoundTransportOrder.delivery_location),
        timezone: mapToTimeZone(apiCompoundTransportOrder.time_zone),
        status: mapToCompoundTransportOrderStatus(apiCompoundTransportOrder.status),
        carrier: apiCompoundTransportOrder.carrier ? mapToCarrier(apiCompoundTransportOrder.carrier) : undefined,
        meansOfTransportId: apiCompoundTransportOrder.means_of_transport_id,
    };
};

export const mapToCompoundTransportOrderStatus = (apiStatus: ApiStatus): CompoundTransportOrderStatus => {
    switch (apiStatus) {
        case ApiStatus.CANCELLATION:
            return CompoundTransportOrderStatus.CANCELLED;
        case ApiStatus.CONFIRMED:
            return CompoundTransportOrderStatus.CONFIRMED;
        case ApiStatus.UNCONFIRMED:
            return CompoundTransportOrderStatus.UNCONFIRMED;
        case ApiStatus.LOADED:
            return CompoundTransportOrderStatus.LOADED;
        case ApiStatus.UNLOADED:
            return CompoundTransportOrderStatus.UNLOADED;
    }
};
