import { FunctionComponent } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/hooks';
import {
    compoundManagementSlice,
    getActiveCompoundManagementView,
    getSelectedCompoundVehicle,
} from '../../reducers/compoundManagementSlice';
import { CompoundManagementView } from '../compoundManagement.types';
import Sidebar from '@rio-cloud/rio-uikit/lib/es/Sidebar';

export const VehicleArchiveSidebar: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const selectedVehicle = useAppSelector(getSelectedCompoundVehicle);
    const activeManagementView = useAppSelector(getActiveCompoundManagementView);

    const closeSidebar = () => {
        dispatch(compoundManagementSlice.actions.deselectCompoundVehicle());
    };

    if (selectedVehicle !== undefined && activeManagementView === CompoundManagementView.ARCHIVE) {
        return (
            <Sidebar closed={false} onClose={closeSidebar}>
                <h1> {`Archive: ${selectedVehicle.id}`}</h1>
            </Sidebar>
        );
    } else {
        return <Sidebar closed />;
    }
};
