import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Table } from '../../shared/table/Table';
import { ArchivedVehicle } from '../../compoundManagement.types';
import { vehicleArchiveColumnDefinition } from './VehicleArchiveTableColumn';
import { VehicleArchiveTableRow } from './VehicleArchiveTableRow';

type VehicleArchiveTableProps = {
    readonly vehicles: readonly ArchivedVehicle[];
};
export const VehicleArchiveTable: FunctionComponent<VehicleArchiveTableProps> = ({ vehicles }) => {
    return (
        <Table
            columnDefinition={vehicleArchiveColumnDefinition}
            items={vehicles}
            notFoundMessage={<FormattedMessage id="outboundOrderBook.compoundManagement.vehicleArchive.notFound" />}
        >
            {vehicles.map((vehicle: ArchivedVehicle) => (
                <VehicleArchiveTableRow key={vehicle.id} archivedVehicle={vehicle} />
            ))}
        </Table>
    );
};
