import ErrorState from '@rio-cloud/rio-uikit/lib/es/ErrorState';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { isUserSessionExpired } from '../../../../configuration/login/loginSlice';
import { useAppSelector } from '../../../../configuration/setup/hooks';
import { useGetArchiveVehiclesQuery } from '../../api/compoundManagementApi';
import { handleQueryError } from '../../notifications/ErrorNotification';
import { LoadMoreButton } from '../../sharedComponents/LoadMoreButton';
import { SortDirection, TableSort } from '../../sharedComponents/SortedTableHeader';
import { useSetUserTriggeredFetching } from '../../sharedComponents/userTriggeredFetchingHook';
import { VehicleArchiveTableToolbar } from './VehicleArchiveTableToolbar';
import { VehicleArchiveTableColumn } from './table/VehicleArchiveTableColumn';
import { VehicleArchiveTable } from './table/VehicleArchiveTable';

export const DEFAULT_COMPOUND_ARCHIVE_LIMIT_SIZE = 100;
const POLLING_INTERVAL = 60000;

export const VehicleArchive: FunctionComponent = () => {
    const isSessionExpired = useAppSelector(isUserSessionExpired);

    const [limit, setLimit] = useState(DEFAULT_COMPOUND_ARCHIVE_LIMIT_SIZE);

    const defaultSort: TableSort<VehicleArchiveTableColumn> = {
        dir: SortDirection.DESCENDING,
        column: VehicleArchiveTableColumn.DEPARTURE_DATE,
    };

    const {
        data: archivePage,
        isLoading,
        isFetching,
        isError,
        error,
    } = useGetArchiveVehiclesQuery(
        {
            limit,
            sortBy: defaultSort,
        },
        {
            pollingInterval: isSessionExpired ? undefined : POLLING_INTERVAL,
        },
    );

    useSetUserTriggeredFetching({
        isFetching,
        depsWhichTriggerUserFetching: [],
    });

    const archiveVehicles = archivePage?.items;

    if (isError) {
        console.error(error);
        handleQueryError(error);
    }

    if (isLoading) {
        return (
            <div data-testid="spinner">
                <Spinner />
            </div>
        );
    }

    if (archiveVehicles === undefined) {
        return (
            <ErrorState
                headline={<FormattedMessage id="intl-msg:common-message.error.generic.headline" />}
                message={<FormattedMessage id="intl-msg:common-message.error.generic.message" />}
            />
        );
    }

    return (
        <>
            <VehicleArchiveTableToolbar />
            <VehicleArchiveTable vehicles={archiveVehicles} />
            {archiveVehicles.length > 0 && (
                <LoadMoreButton
                    onLoadMore={() => setLimit(limit + DEFAULT_COMPOUND_ARCHIVE_LIMIT_SIZE)}
                    hasMoreToLoad={archivePage?.hasMore ?? false}
                />
            )}
        </>
    );
};
