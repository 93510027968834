import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CompoundManagementView, CompoundVehicle } from '../compoundManagement/compoundManagement.types';
import type { RootState } from '../../../configuration/setup/store';
import { VehicleType } from '../sharedComponents/common.types';

const NO_SELECTED_COMPOUND_VEHICLE = undefined;

export type InventoryVehicleModalFilter = {
    locationIdentifierFilter: string | undefined;
    vehicleTypeFilter: VehicleType | undefined;
    manufacturerNameFilter: string | undefined;
    arrivalDateStartAtFilter: Date | undefined;
    arrivalDateEndAtFilter: Date | undefined;
};

export type CompoundManagementState = {
    activeCompoundManagementView: CompoundManagementView;
    selectedVehicle: CompoundVehicle | undefined;
    inventoryVehicleModalFilter: InventoryVehicleModalFilter;
};

const initialCompoundManagementState: CompoundManagementState = {
    activeCompoundManagementView: CompoundManagementView.INVENTORY,
    selectedVehicle: NO_SELECTED_COMPOUND_VEHICLE,
    inventoryVehicleModalFilter: {
        locationIdentifierFilter: undefined,
        vehicleTypeFilter: undefined,
        manufacturerNameFilter: undefined,
        arrivalDateStartAtFilter: undefined,
        arrivalDateEndAtFilter: undefined,
    },
};

export const compoundManagementSlice = createSlice({
    name: 'compoundManagement',
    initialState: initialCompoundManagementState,
    reducers: {
        switchView: (state: CompoundManagementState, action: PayloadAction<CompoundManagementView>) => {
            state.activeCompoundManagementView = action.payload;
        },
        setInventoryVehicleModalFilter: (
            state: CompoundManagementState,
            action: PayloadAction<InventoryVehicleModalFilter>,
        ) => {
            state.inventoryVehicleModalFilter = action.payload;
        },
        selectCompoundVehicle: (state: CompoundManagementState, action: PayloadAction<CompoundVehicle>) => {
            state.selectedVehicle = action.payload;
        },
        deselectCompoundVehicle: (state: CompoundManagementState) => {
            state.selectedVehicle = NO_SELECTED_COMPOUND_VEHICLE;
        },
        setInitialState: () => initialCompoundManagementState,
    },
});

export const getActiveCompoundManagementView = (state: RootState) =>
    state.portal.compoundManagement.activeCompoundManagementView;
export const getInventoryVehicleModalFilter = (state: RootState) =>
    state.portal.compoundManagement.inventoryVehicleModalFilter;
export const getSelectedCompoundVehicle = (state: RootState) => state.portal.compoundManagement.selectedVehicle;
