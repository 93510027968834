import { FunctionComponent } from 'react';
import { ColumnDefinition, ColumnTableHeader } from '../../../sharedComponents/SortedTableHeader';

type TableHeaderProps = {
    columnDefinition: ColumnDefinition<any>[];
};

// TODO use this for all tables?
export const TableHeader: FunctionComponent<TableHeaderProps> = ({ columnDefinition }) => {
    return (
        <thead>
            <tr>
                {columnDefinition.map((def) => (
                    <ColumnTableHeader key={def.column} labelId={def.labelId} />
                ))}
            </tr>
        </thead>
    );
};
