import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { decodeResponse, validateResponse } from './responseUtil';
import { prepareHeaders } from './utils';
import { config } from '../../../config';
import { VehicleArchiveTableColumn } from '../compoundManagement/archive/table/VehicleArchiveTableColumn';
import { ArchivedVehiclesPage, InventoryVehiclesPage } from '../compoundManagement/compoundManagement.types';
import { TableSort } from '../sharedComponents/SortedTableHeader';
import {
    mapToArchivedVehicleQueryParameters,
    mapToArchivedVehiclesPage,
    mapToInventoryVehicleQueryParameters,
    mapToInventoryVehiclesPage,
} from './mappers/compoundManagement/compoundManagementMappers';
import { ArchivedVehiclesPageCodec, InventoryVehiclesPageCodec } from './types/compoundManagementApi.types';
import { VehicleInventoryTableColumn } from '../compoundManagement/inventory/table/VehicleInventoryTableColumn';

export const COMPOUND_MANAGEMENT_INVENTORY_VEHICLES_PATH = 'compound-inventory-vehicles';
export const COMPOUND_MANAGEMENT_ARCHIVED_VEHICLES_PATH = 'compound-archived-vehicles';

export type GetInventoryVehiclesQueryParams = {
    sortBy?: TableSort<VehicleInventoryTableColumn>;
    limit?: number;
};

export type GetArchivedVehiclesQueryParams = {
    sortBy?: TableSort<VehicleArchiveTableColumn>;
    limit?: number;
};

const InventoryVehiclesTag = 'InventoryVehiclesTag';

export const compoundManagementApi = createApi({
    reducerPath: 'compoundManagementApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.backend.FINISHED_VEHICLES_SERVICE, prepareHeaders }),
    tagTypes: [InventoryVehiclesTag],
    endpoints: (builder) => ({
        getInventoryVehicles: builder.query<InventoryVehiclesPage, GetInventoryVehiclesQueryParams>({
            query: (queryParameters) => ({
                url: COMPOUND_MANAGEMENT_INVENTORY_VEHICLES_PATH,
                params: mapToInventoryVehicleQueryParameters(queryParameters),
                validateStatus: validateResponse(InventoryVehiclesPageCodec),
            }),
            transformResponse: decodeResponse(InventoryVehiclesPageCodec, mapToInventoryVehiclesPage),
            providesTags: [InventoryVehiclesTag],
        }),
        getArchiveVehicles: builder.query<ArchivedVehiclesPage, GetArchivedVehiclesQueryParams>({
            query: (queryParameters) => ({
                url: COMPOUND_MANAGEMENT_ARCHIVED_VEHICLES_PATH,
                params: mapToArchivedVehicleQueryParameters(queryParameters),
                validateStatus: validateResponse(ArchivedVehiclesPageCodec),
            }),
            transformResponse: decodeResponse(ArchivedVehiclesPageCodec, mapToArchivedVehiclesPage),
            providesTags: [InventoryVehiclesTag],
        }),
    }),
});

export const { useGetInventoryVehiclesQuery, useGetArchiveVehiclesQuery } = compoundManagementApi;
