import { FunctionComponent } from 'react';
import { useAppSelector } from '../../../configuration/setup/hooks';
import { getActiveCompoundManagementView } from '../reducers/compoundManagementSlice';
import { VehicleArchive } from './archive/VehicleArchive';
import { VehicleInventory } from './inventory/VehicleInventory';
import { CompoundManagementView } from './compoundManagement.types';

export const CompoundManagement: FunctionComponent = () => {
    const activeView = useAppSelector(getActiveCompoundManagementView);
    switch (activeView) {
        case CompoundManagementView.INVENTORY:
            return <VehicleInventory />;
        case CompoundManagementView.ARCHIVE:
            return <VehicleArchive />;
    }
};
