import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

import { DefaultUserMenu } from '@rio-cloud/rio-user-menu-component';
import ApplicationHeader from '@rio-cloud/rio-uikit/lib/es/ApplicationHeader';
import IframeResizer from 'iframe-resizer-react';

import { config } from '../../../config';
import { ServiceInfo } from './ServiceInfo';
import {
    COMPOUND_MANAGEMENT_ROUTE,
    INCOMING_COMPOUND_TRANSPORT_ORDER_ROUTE,
    SERVICE_ORDER_ROUTE,
    SERVICE_PROVIDER_ACTIVATION_ROUTE,
    TRANSPORT_ORDER_ROUTE,
    WELCOME_ROUTE,
} from '../routes/routes';
import classNames from 'classnames';
import { FeatureToggles, useFeatureToggle } from '../../../configuration/featureToggle/toggleHook';

export const AppHeader = (props: { isActive: boolean }) => {
    const isLspAdministrationEnabled = useFeatureToggle(FeatureToggles.LSP_ADMINISTRATION, false).value;
    const isCompoundManagementEnabled = useFeatureToggle(FeatureToggles.COMPOUND_MANAGEMENT, false).value;

    const navItems = [
        {
            key: 'welcome',
            route: (
                <NavLink to={WELCOME_ROUTE}>
                    <FormattedMessage id="outboundPortal.header.navigation.welcome" />
                </NavLink>
            ),
        },
        {
            key: 'transport-orders',
            route: (
                <NavLink
                    to={TRANSPORT_ORDER_ROUTE}
                    className={classNames({
                        disabled: !props.isActive,
                    })}
                >
                    <FormattedMessage id="outboundPortal.header.navigation.transportOrders" />
                </NavLink>
            ),
        },
        {
            key: 'service-orders',
            route: (
                <NavLink
                    to={SERVICE_ORDER_ROUTE}
                    className={classNames({
                        disabled: !props.isActive,
                    })}
                >
                    <FormattedMessage id="outboundPortal.header.navigation.serviceOrders" />
                </NavLink>
            ),
        },
        {
            key: 'incoming-vehicles',
            route: (
                <NavLink
                    to={INCOMING_COMPOUND_TRANSPORT_ORDER_ROUTE}
                    className={classNames({
                        disabled: !props.isActive,
                    })}
                >
                    <FormattedMessage id="outboundPortal.header.navigation.incomingCompoundTransportOrders" />
                </NavLink>
            ),
        },
    ];

    if (isLspAdministrationEnabled) {
        navItems.push({
            key: 'service-provider-activation',
            route: (
                <NavLink
                    to={SERVICE_PROVIDER_ACTIVATION_ROUTE}
                    className={classNames({
                        disabled: !props.isActive,
                    })}
                >
                    <FormattedMessage id="outboundPortal.header.navigation.serviceProviderActivation" />
                </NavLink>
            ),
        });
    }

    if (isCompoundManagementEnabled) {
        navItems.push({
            key: 'compound-management',
            route: (
                <NavLink
                    to={COMPOUND_MANAGEMENT_ROUTE}
                    className={classNames({
                        disabled: !props.isActive,
                    })}
                >
                    <FormattedMessage id="outboundPortal.header.navigation.compoundManagement" />
                </NavLink>
            ),
        });
    }

    const environment = import.meta.env.MODE === 'production' ? 'production' : 'local';

    const serviceInfoItem = <ServiceInfo />;
    const userMenuItem = <DefaultUserMenu environment={environment} />;

    const homeLink = <a href={config.homeRoute !== undefined ? config.homeRoute : ''} />;

    return (
        <ApplicationHeader
            label={config.application.name}
            appNavigator={<IframeResizer className="iFrameResizer" src={config.backend.MENU_SERVICE} />}
            homeRoute={homeLink}
            navItems={navItems}
            actionBarItems={[serviceInfoItem, userMenuItem]}
        />
    );
};
