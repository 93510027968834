import { ColumnDefinition } from '../../sharedComponents/SortedTableHeader';

export enum TransportAssignmentTableColumn {
    HAS_CHANGES = 'hasChanges',
    EXTERNAL_ID = 'externalId',
    STATUS = 'status',
    MODEL = 'model',
    MODEL_GROUP = 'modelGroup',
    FROM = 'from',
    REQUESTED_PICKUP = 'requestedPickup',
    TO = 'to',
    REQUESTED_DELIVERY = 'requestedDelivery',
    MEANS_OF_TRANSPORT = 'meansOfTransport',
    ACTION = 'action',
}

export const transportAssignmentColumnDefinition: ColumnDefinition<TransportAssignmentTableColumn>[] = [
    {
        column: TransportAssignmentTableColumn.HAS_CHANGES,
        labelId: undefined,
        sortable: false,
    },
    {
        column: TransportAssignmentTableColumn.EXTERNAL_ID,
        labelId: 'outboundPortal.transportAssignments.table.loadOrder',
        sortable: false,
    },
    {
        column: TransportAssignmentTableColumn.STATUS,
        labelId: 'outboundOrderBook.common.heading.status',
        sortable: true,
    },
    {
        column: TransportAssignmentTableColumn.MODEL,
        labelId: 'outboundOrderBook.common.heading.modelOrCapacity',
        sortable: false,
    },
    {
        column: TransportAssignmentTableColumn.MODEL_GROUP,
        labelId: 'outboundPortal.transportAssignments.table.modelGroup',
        sortable: false,
    },
    {
        column: TransportAssignmentTableColumn.FROM,
        labelId: 'outboundPortal.transportAssignments.table.from',
        sortable: true,
    },
    {
        column: TransportAssignmentTableColumn.REQUESTED_PICKUP,
        labelId: 'outboundPortal.transportAssignments.table.pickUpDate',
        sortable: true,
    },
    {
        column: TransportAssignmentTableColumn.TO,
        labelId: 'outboundPortal.transportAssignments.table.to',
        sortable: true,
    },
    {
        column: TransportAssignmentTableColumn.REQUESTED_DELIVERY,
        labelId: 'outboundPortal.transportAssignments.table.deliveryDate',
        sortable: true,
    },
    {
        column: TransportAssignmentTableColumn.MEANS_OF_TRANSPORT,
        labelId: 'outboundOrderBook.common.heading.meansOfTransport',
        sortable: false,
    },
    {
        column: TransportAssignmentTableColumn.ACTION,
        labelId: 'outboundPortal.transportAssignments.table.action',
        sortable: false,
    },
];
