import React, { ReactElement, useState } from 'react';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    FilterModalButton,
    FilterModalDatePicker,
    FilterModalFooter,
    FilterModalTextInput,
} from '../../sharedComponents/FilterModal';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/hooks';
import { compoundManagementSlice, getInventoryVehicleModalFilter } from '../../reducers/compoundManagementSlice';
import { isDate } from 'date-fns';
import { VehicleType } from '../../sharedComponents/common.types';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';
import moment, { Moment } from 'moment/moment';

type StatusFilterOption = {
    id: VehicleType;
    label: ReactElement;
};

interface LocalInventoryVehicleModalFilter {
    locationIdentifierFilter: string | undefined;
    vehicleTypeFilter: VehicleType | undefined;
    manufacturerNameFilter: string | undefined;
    arrivalDateStartAtFilter: Date | undefined | string;
    arrivalDateEndAtFilter: Date | undefined | string;
}

const UNSET_INVENTORY_VEHICLE_MODAL_FILTER = {
    locationIdentifierFilter: undefined,
    vehicleTypeFilter: undefined,
    manufacturerNameFilter: undefined,
    arrivalDateStartAtFilter: undefined,
    arrivalDateEndAtFilter: undefined,
};

const discardInvalidDateInputs = (localFilter: LocalInventoryVehicleModalFilter) => ({
    arrivalDateStartAtFilter: isDate(localFilter.arrivalDateStartAtFilter)
        ? (localFilter.arrivalDateStartAtFilter as Date)
        : undefined,
    arrivalDateEndAtFilter: isDate(localFilter.arrivalDateEndAtFilter)
        ? (localFilter.arrivalDateEndAtFilter as Date)
        : undefined,
});

export const CompoundManagementFilterModal = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const modalFilter = useAppSelector(getInventoryVehicleModalFilter);
    const [showModal, setShowModal] = useState(false);
    const [localFilters, setLocalFilters] = useState<LocalInventoryVehicleModalFilter>(
        UNSET_INVENTORY_VEHICLE_MODAL_FILTER,
    );

    const handleOnClickApply = () => {
        setLocalFilters({
            ...localFilters,
            locationIdentifierFilter: localFilters.locationIdentifierFilter?.trim(),
            manufacturerNameFilter: localFilters.manufacturerNameFilter?.trim(),
        });
        dispatch(
            compoundManagementSlice.actions.setInventoryVehicleModalFilter({
                ...localFilters,
                ...discardInvalidDateInputs(localFilters),
                locationIdentifierFilter: localFilters.locationIdentifierFilter,
                manufacturerNameFilter: localFilters.manufacturerNameFilter,
            }),
        );
        setShowModal(false);
    };

    const handleOnClickCancel = () => {
        setLocalFilters(modalFilter);
        setShowModal(false);
    };

    const resetFilters = () => {
        setLocalFilters(UNSET_INVENTORY_VEHICLE_MODAL_FILTER);
    };

    const vehicleTypeOptions: StatusFilterOption[] = [
        { id: VehicleType.NEW, label: <FormattedMessage id="outboundOrderBook.common.vehicleType.new" /> },
        { id: VehicleType.USED, label: <FormattedMessage id="outboundOrderBook.common.vehicleType.used" /> },
        { id: VehicleType.OTHER, label: <FormattedMessage id="outboundOrderBook.common.vehicleType.other" /> },
    ];

    const nextDateValue = (date: Moment | string | undefined) =>
        moment.isMoment(date) ? date.toDate() : date === '' ? undefined : date;

    const content = (
        <div className="margin-bottom-20">
            <FilterModalTextInput
                headerMessageId="outboundOrderBook.compoundTransportOrder.table.toolbar.filter.locationId"
                placeholderMessageId="outboundOrderBook.common.filter.enterLocationId"
                value={localFilters.locationIdentifierFilter}
                onChange={(event) => {
                    const value = event.currentTarget.value;
                    setLocalFilters((prevState) => ({
                        ...prevState,
                        locationIdentifierFilter: value === '' ? undefined : value,
                    }));
                }}
            />

            <div className="margin-top-20 margin-bottom-20">
                <label htmlFor="dispatch-select" className="text-size-14 text-bold text-color-darkest">
                    <FormattedMessage id="outboundOrderBook.common.filter.dispatchType" />
                </label>
                <Select
                    id="dispatch-select"
                    placeholder={intl.formatMessage({
                        id: 'outboundOrderBook.common.filter.selectDispatchType',
                    })}
                    options={vehicleTypeOptions}
                    value={
                        localFilters.vehicleTypeFilter === undefined
                            ? undefined
                            : [localFilters.vehicleTypeFilter.valueOf()]
                    }
                    onChange={(item) =>
                        setLocalFilters((prevState) => ({
                            ...prevState,
                            vehicleTypeFilter: item.id,
                        }))
                    }
                />
            </div>

            <FilterModalTextInput
                headerMessageId="outboundOrderBook.common.filter.manufacturer"
                placeholderMessageId="outboundOrderBook.common.filter.enterManufacturer"
                value={localFilters.manufacturerNameFilter}
                onChange={(event) => {
                    const value = event.currentTarget.value;
                    setLocalFilters((prevState) => ({
                        ...prevState,
                        manufacturerNameFilter: value === '' ? undefined : value,
                    }));
                }}
            />

            <div className="margin-top-20">
                <div className="text-size-14 text-bold margin-bottom-10">
                    <FormattedMessage id="outboundOrderBook.common.filter.arrivalTimeframe" />
                </div>
                <div className="display-flex justify-content-start row">
                    <div className="col-6 padding-right-5">
                        <FilterModalDatePicker
                            labelId="outboundPortal.transportAssignments.table.from"
                            hasFormatError={false}
                            hasTimeRangeOrderError={false}
                            value={localFilters.arrivalDateStartAtFilter}
                            onChange={(date: Moment | string | undefined) =>
                                setLocalFilters((prevState) => ({
                                    ...prevState,
                                    arrivalDateStartAtFilter: nextDateValue(date),
                                }))
                            }
                        />
                    </div>
                    <div className="col-6 padding-left-5">
                        <FilterModalDatePicker
                            labelId="outboundPortal.transportAssignments.table.to"
                            hasFormatError={false}
                            hasTimeRangeOrderError={false}
                            value={localFilters.arrivalDateEndAtFilter}
                            onChange={(date: Moment | string | undefined) =>
                                setLocalFilters((prevState) => ({
                                    ...prevState,
                                    arrivalDateEndAtFilter: nextDateValue(date),
                                }))
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <>
            <FilterModalButton isFilterActive={false} setShowFilterModal={setShowModal} />
            <Dialog
                show={showModal}
                bsSize={Dialog.SIZE_SM}
                title={<FormattedMessage id="outboundOrderBook.common.table.toolbar.filter.title" />}
                body={content}
                footer={
                    <FilterModalFooter
                        handleOnClickApply={handleOnClickApply}
                        handleOnClickCancel={handleOnClickCancel}
                        resetFilters={resetFilters}
                        isApplyButtonDisabled={false}
                    />
                }
                onHide={handleOnClickCancel}
            />
        </>
    );
};
