import { VehicleArchiveTableColumn } from '../../../compoundManagement/archive/table/VehicleArchiveTableColumn';
import { VehicleInventoryTableColumn } from '../../../compoundManagement/inventory/table/VehicleInventoryTableColumn';
import {
    ArchivedVehicle,
    ArchivedVehiclesPage,
    Arrival,
    CompoundLocation,
    Departure,
    FreightForwarder,
    InventoryVehicle,
    InventoryVehiclesPage,
} from '../../../compoundManagement/compoundManagement.types';
import { parseStringDate } from '../../../sharedComponents/dateHelper';
import { SortDirection, TableSort } from '../../../sharedComponents/SortedTableHeader';
import type { GetArchivedVehiclesQueryParams, GetInventoryVehiclesQueryParams } from '../../compoundManagementApi';
import {
    ApiArchivedVehicle,
    ApiArchivedVehicleQueryParameter,
    ApiArchivedVehicleSort,
    ApiArchivedVehiclesPage,
    ApiArrival,
    ApiCompoundLocation,
    ApiDeparture,
    ApiFreightForwarder,
    ApiInventoryVehicle,
    ApiInventoryVehicleQueryParameter,
    ApiInventoryVehicleSort,
    ApiInventoryVehiclesPage,
} from '../../types/compoundManagementApi.types';
import { mapToVehicle } from './vehicleCompoundMapper';

export const mapToArchivedVehicleQueryParameters = (
    queryParameters: GetArchivedVehiclesQueryParams,
): ApiArchivedVehicleQueryParameter => ({
    limit: queryParameters?.limit?.toFixed(0),
    sort: queryParameters.sortBy && mapToApiArchivedVehicleSort(queryParameters.sortBy),
});

export const mapToInventoryVehicleQueryParameters = (
    queryParameters: GetInventoryVehiclesQueryParams,
): ApiInventoryVehicleQueryParameter => ({
    limit: queryParameters?.limit?.toFixed(0),
    sort: queryParameters.sortBy && mapToApiInventoryVehicleSort(queryParameters.sortBy),
});

export const mapToApiArchivedVehicleSort = (sorting: TableSort<VehicleArchiveTableColumn>): ApiArchivedVehicleSort => {
    if (sorting.dir === SortDirection.ASCENDING) {
        switch (sorting.column) {
            case VehicleArchiveTableColumn.DEPARTURE_DATE:
                return ApiArchivedVehicleSort.DEPARTURE_COMPLETED_AT_ASC;
        }
    } else if (sorting.dir === SortDirection.DESCENDING) {
        switch (sorting.column) {
            case VehicleArchiveTableColumn.DEPARTURE_DATE:
                return ApiArchivedVehicleSort.DEPARTURE_COMPLETED_AT_DESC;
        }
    }
    throw new Error('Specified sort order is not implemented');
};

export const mapToApiInventoryVehicleSort = (
    sorting: TableSort<VehicleInventoryTableColumn>,
): ApiInventoryVehicleSort => {
    if (sorting.dir === SortDirection.ASCENDING) {
        switch (sorting.column) {
            case VehicleInventoryTableColumn.ARRIVAL_DATE:
                return ApiInventoryVehicleSort.ARRIVAL_COMPLETED_AT_ASC;
        }
    } else if (sorting.dir === SortDirection.DESCENDING) {
        switch (sorting.column) {
            case VehicleInventoryTableColumn.ARRIVAL_DATE:
                return ApiInventoryVehicleSort.ARRIVAL_COMPLETED_AT_DESC;
        }
    }
    throw new Error('Specified sort order is not implemented');
};

const mapToInventoryVehicle = (apiVehicle: ApiInventoryVehicle): InventoryVehicle => ({
    id: apiVehicle.id,
    compoundLocation: mapToCompoundLocation(apiVehicle.compound_location),
    arrival: mapToArrival(apiVehicle.arrival),
    vehicle: mapToVehicle(apiVehicle.vehicle),
});

const mapToArchivedVehicle = (apiVehicle: ApiArchivedVehicle): ArchivedVehicle => ({
    id: apiVehicle.id,
    compoundLocation: mapToCompoundLocation(apiVehicle.compound_location),
    arrival: mapToArrival(apiVehicle.arrival),
    departure: mapToDeparture(apiVehicle.departure),
    vehicle: mapToVehicle(apiVehicle.vehicle),
});

export const mapToInventoryVehiclesPage = (apiPage: ApiInventoryVehiclesPage): InventoryVehiclesPage => ({
    items: apiPage.items.map(mapToInventoryVehicle),
    hasMore: false, // TODO PORTALOUT-2527 retrieve this from the API
});

export const mapToArchivedVehiclesPage = (apiPage: ApiArchivedVehiclesPage): ArchivedVehiclesPage => ({
    items: apiPage.items.map(mapToArchivedVehicle),
    hasMore: false, // TODO PORTALOUT-2527 retrieve this from the API
});

const mapToCompoundLocation = (apiCompoundLocation: ApiCompoundLocation): CompoundLocation => ({
    identifier: apiCompoundLocation.identifier,
    city: apiCompoundLocation.city,
    timeZone: apiCompoundLocation.time_zone,
});

export const mapToFreightForwarder = (apiFreightForwarder: ApiFreightForwarder): FreightForwarder => ({
    name: apiFreightForwarder.name,
    additionalName: apiFreightForwarder.additional_name,
});

export const mapToArrival = (apiArrival: ApiArrival): Arrival => ({
    arrivalCompletedAt: parseStringDate(apiArrival.arrival_completed_at),
    freightForwarder: apiArrival.freight_forwarder && mapToFreightForwarder(apiArrival.freight_forwarder),
});

export const mapToDeparture = (apiDeparture: ApiDeparture): Departure => ({
    departureCompletedAt: parseStringDate(apiDeparture.departure_completed_at),
    freightForwarder: apiDeparture.freight_forwarder && mapToFreightForwarder(apiDeparture.freight_forwarder),
});
