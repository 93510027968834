import { FormattedMessage } from 'react-intl';
import { DateTime } from '../../../sharedComponents/BaseTableComponents';
import { NoValue } from '../../../sharedComponents/NoValue';
import { InventoryVehicle } from '../../compoundManagement.types';
import { vehicleInventoryColumnDefinition, VehicleInventoryTableColumn } from './VehicleInventoryTableColumn';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/hooks';
import { compoundManagementSlice, getSelectedCompoundVehicle } from '../../../reducers/compoundManagementSlice';

type TableRowDataProps = {
    column: VehicleInventoryTableColumn;
    vehicle: InventoryVehicle;
};

export const TableDataContent = ({ vehicle, column }: TableRowDataProps) => {
    const spanOrNoValue = (text: string | undefined) => (Boolean(text) ? <span>{text}</span> : <NoValue />);

    switch (column) {
        case VehicleInventoryTableColumn.VIN:
            return <div className="ellipsis-1">{vehicle.vehicle.vin}</div>;
        case VehicleInventoryTableColumn.LOCATION:
            return <span>{vehicle.compoundLocation.city}</span>;
        case VehicleInventoryTableColumn.MANUFACTURER:
            return spanOrNoValue(vehicle.vehicle.model.manufacturer);
        case VehicleInventoryTableColumn.MODEL:
            return <span>{vehicle.vehicle.model.name}</span>;
        case VehicleInventoryTableColumn.DISPATCH_TYPE:
            return vehicle.vehicle.vehicleType === undefined ? (
                <NoValue />
            ) : (
                <div className="ellipsis-1">
                    <FormattedMessage id={`outboundOrderBook.common.vehicleType.${vehicle.vehicle.vehicleType}`} />
                </div>
            );
        case VehicleInventoryTableColumn.FUEL_TYPE:
            const fuelType = vehicle.vehicle.model.fuelType;
            if (fuelType === undefined) {
                return <NoValue />;
            } else {
                const messageId = `outboundOrderBook.common.fuelType.${fuelType}`;
                return (
                    <div className="ellipsis-1">
                        <FormattedMessage id={messageId} />
                    </div>
                );
            }
        case VehicleInventoryTableColumn.MODEL_VERSION:
            return spanOrNoValue(vehicle.vehicle.model.version);
        case VehicleInventoryTableColumn.ARRIVAL_DATE:
            return (
                <DateTime dateTime={vehicle.arrival.arrivalCompletedAt} timeZone={vehicle.compoundLocation.timeZone} />
            );
        case VehicleInventoryTableColumn.ARRIVAL_FREIGHT_FORWARDER:
            return vehicle.arrival.freightForwarder === undefined ? (
                <NoValue />
            ) : (
                <div className="ellipsis-1">
                    {vehicle.arrival.freightForwarder.name} {vehicle.arrival.freightForwarder.additionalName}
                </div>
            );
        default:
            throw new Error('exhaustive switch exception - table content');
    }
};

const TableRowData = ({ column, vehicle }: TableRowDataProps) => (
    <td key={column}>
        <TableDataContent column={column} vehicle={vehicle} />
    </td>
);

type VehicleInventoryTableRowProps = {
    readonly inventoryVehicle: InventoryVehicle;
};

export const VehicleInventoryTableRow = ({ inventoryVehicle }: VehicleInventoryTableRowProps) => {
    const dispatch = useAppDispatch();
    const selectedCompoundVehicle = useAppSelector(getSelectedCompoundVehicle);

    const handleRowClick = () => {
        const isRowSelected = inventoryVehicle.id === selectedCompoundVehicle?.id;
        if (isRowSelected) {
            dispatch(compoundManagementSlice.actions.deselectCompoundVehicle());
        } else {
            dispatch(compoundManagementSlice.actions.selectCompoundVehicle(inventoryVehicle));
        }
    };

    return (
        <tr key={inventoryVehicle.id} onClick={handleRowClick}>
            {vehicleInventoryColumnDefinition.map((def) => (
                <TableRowData key={def.column} column={def.column} vehicle={inventoryVehicle} />
            ))}
        </tr>
    );
};
