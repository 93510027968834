import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import { PropsWithChildren } from 'react';
import { useAppSelector } from '../../../../../configuration/setup/hooks';
import { getTableViewType } from '../../../reducers/viewSlice';
import { ContentLoadingRow } from '../../../sharedComponents/BaseTableComponents';
import { ColumnDefinition, getTableClassNames } from '../../../sharedComponents/SortedTableHeader';
import { useGetUserTriggeredFetching } from '../../../sharedComponents/userTriggeredFetchingHook';
import { TableHeader } from '../../shared/table/TableHeader';

type TableProps = PropsWithChildren<{
    columnDefinition: ColumnDefinition<any>[];
    notFoundMessage: JSX.Element;
    readonly items: readonly unknown[];
}>;

// TODO use this for all tables?
export const Table = ({ items, notFoundMessage, children, columnDefinition }: TableProps) => {
    const viewType = useAppSelector(getTableViewType);
    const isFetchingQueryTriggerByUser = useGetUserTriggeredFetching();

    const tableClassNames = getTableClassNames(items, viewType);

    return (
        <table className={tableClassNames}>
            <colgroup>
                {columnDefinition.map((def) => (
                    <col key={def.column} />
                ))}
            </colgroup>
            <TableHeader columnDefinition={columnDefinition} />
            <tbody>
                {isFetchingQueryTriggerByUser ? (
                    <ContentLoadingRow numberOfColumns={columnDefinition.length} />
                ) : items.length <= 0 ? (
                    <tr>
                        <td colSpan={columnDefinition.length}>
                            <NotFoundState headline={notFoundMessage} outerClassName="border-none" />
                        </td>
                    </tr>
                ) : (
                    children
                )}
            </tbody>
        </table>
    );
};
