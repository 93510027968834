import * as t from 'io-ts';
import { NonEmptyString } from 'io-ts-types';
import { fromEnum } from '../responseUtil';
import { VehicleCodec } from './compoundManagement/vehicleCompound.types';

export enum ApiSort {
    ARRIVAL_COMPLETED_AT_ASC = '+arrival_completed_at',
    ARRIVAL_COMPLETED_AT_DESC = '-arrival_completed_at',
    DEPARTURE_COMPLETED_AT_ASC = '+departure_completed_at',
    DEPARTURE_COMPLETED_AT_DESC = '-departure_completed_at',
}

export enum ApiArchivedVehicleSort {
    DEPARTURE_COMPLETED_AT_ASC = '+departure_completed_at',
    DEPARTURE_COMPLETED_AT_DESC = '-departure_completed_at',
}

export enum ApiInventoryVehicleSort {
    ARRIVAL_COMPLETED_AT_ASC = '+arrival_completed_at',
    ARRIVAL_COMPLETED_AT_DESC = '-arrival_completed_at',
}

const ArchivedVehicleQueryParameterCodec = t.partial({
    sort: fromEnum<ApiArchivedVehicleSort>('ApiArchivedVehicleSort', ApiArchivedVehicleSort),
    limit: t.string,
});

const InventoryVehicleQueryParameterCodec = t.partial({
    sort: fromEnum<ApiInventoryVehicleSort>('ApiInventoryVehicleSort', ApiInventoryVehicleSort),
    limit: t.string,
});

const QueryParameterCodec = t.partial({
    sort: fromEnum<ApiSort>('ApiSort', ApiSort),
    limit: t.string,
});

const CompoundLocationCodec = t.type({ identifier: NonEmptyString, city: NonEmptyString, time_zone: NonEmptyString });

const FreightForwarderCodec = t.intersection([
    t.type({ name: NonEmptyString }),
    t.partial({ additional_name: NonEmptyString }),
]);

const ArrivalCodec = t.intersection([
    t.type({ arrival_completed_at: NonEmptyString }),
    t.partial({ freight_forwarder: FreightForwarderCodec }),
]);

const DepartureCodec = t.intersection([
    t.type({ departure_completed_at: NonEmptyString }),
    t.partial({ freight_forwarder: FreightForwarderCodec }),
]);

const InventoryVehicleCodec = t.type({
    id: NonEmptyString,
    compound_location: CompoundLocationCodec,
    arrival: ArrivalCodec,
    vehicle: VehicleCodec,
});

const ArchivedVehicleCodec = t.intersection([InventoryVehicleCodec, t.type({ departure: DepartureCodec })]);

export const ArchivedVehiclesPageCodec = t.type({
    items: t.array(ArchivedVehicleCodec),
});

export const InventoryVehiclesPageCodec = t.type({
    items: t.array(InventoryVehicleCodec),
});

export type ApiCompoundManagementVehicleInventoryQueryParameter = t.TypeOf<typeof QueryParameterCodec>;
export type ApiInventoryVehicleQueryParameter = t.TypeOf<typeof InventoryVehicleQueryParameterCodec>;
export type ApiArchivedVehicleQueryParameter = t.TypeOf<typeof ArchivedVehicleQueryParameterCodec>;
export type ApiInventoryVehicle = t.TypeOf<typeof InventoryVehicleCodec>;
export type ApiArchivedVehicle = t.TypeOf<typeof ArchivedVehicleCodec>;
export type ApiInventoryVehiclesPage = t.TypeOf<typeof InventoryVehiclesPageCodec>;
export type ApiArchivedVehiclesPage = t.TypeOf<typeof ArchivedVehiclesPageCodec>;
export type ApiCompoundLocation = t.TypeOf<typeof CompoundLocationCodec>;
export type ApiFreightForwarder = t.TypeOf<typeof FreightForwarderCodec>;
export type ApiArrival = t.TypeOf<typeof ArrivalCodec>;
export type ApiDeparture = t.TypeOf<typeof DepartureCodec>;
